import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { CorrectionsDetailDataService } from '@corrections/shared/services/corrections-detail-data/corrections-detail-data.service';
import { DateFormat } from '@shared/enums/common-data/date-format.enum';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { RatingCancelChargesEnum } from '@shared/enums/common-data/tariffCancelCharges.enum';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { ManualRateIndMapperService } from '@shared/mappers/common/manual-rate-ind/manual-rate-ind-mapper.service';
import { CorrectionsBillChargesModel } from '@shared/models/corrections/bill-payment/corrections-bill-charges.model';
import { CorrectionsDetailModel } from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsFormCreateRequestResponseModel, CorrectionsFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { CreateGetRequestMapperParams } from '@shared/models/shared/mappers/create-request-mapper.model';
import { TransformCommonDataFieldsService } from '@shared/services/application-utils/transforms/common-data-fields/transform-common-data-fields.service';
import { RequestFormRolesService } from '@shared/services/application-utils/user-roles/request-form/request-form-roles.service';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { ObjectUtils } from '@shared/utils/object/object-utils.service';
import {
  CorrectionAccessorial,
  CorrectionRequest,
  CorrectionShipment,
  UpsertCorrectionRequestResp,
  UpsertCorrectionRequestRqst
} from '@xpo-ltl-2.0/sdk-billcorrection';
import {
  AdvanceBeyondCarrier,
  AdvanceBeyondTypeCd,
  AsMatchedParty,
  Bond,
  ChargeToCd,
  Commodity,
  MatchedPartyTypeCd,
  MiscLineItem,
  MiscLineItemCd,
  MonetaryAmount
} from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CorrectionsCreateRequestCommonMapperService } from './common/corrections-create-request-common-mapper.service';

@Injectable()
export class CorrectionsCreateRequestMapperService {

  constructor(
    private correctionsCreateRequestCommonMapperService: CorrectionsCreateRequestCommonMapperService,
    private transformCommonDataFieldsService: TransformCommonDataFieldsService,
    private correctionsHandler: CorrectionsHandler,
    private correctionsDetailDataService: CorrectionsDetailDataService,
    private manualRateIndMapperService: ManualRateIndMapperService,
    private requestFormRolesService: RequestFormRolesService,
  ) { }

  // RESPONSE *********************************************************************************************************************
  getResponse(apiResponse: UpsertCorrectionRequestResp): CorrectionsFormCreateRequestResponseModel {
    return this.correctionsCreateRequestCommonMapperService.getResponse(apiResponse);
  }

  // REQUEST ***********************************************************************************************************************
  // NOTE: When the user clears the value of a field, it must be sent as null
  getRequest(createGetRequestMapperParams: CreateGetRequestMapperParams): UpsertCorrectionRequestRqst {
    ObjectUtils.setNullWhenIsDeleted(createGetRequestMapperParams.correctionsForm, createGetRequestMapperParams.originalForm);
    const correctionsDetail = this.getCorrectionDetail();
    const request = new UpsertCorrectionRequestRqst();
    request.correctionRequest = this.getCorrectionRequest(createGetRequestMapperParams, correctionsDetail);
    request.shipment = this.getShipment(createGetRequestMapperParams, correctionsDetail);
    request.emailAddresses = this.correctionsCreateRequestCommonMapperService.getEmailAddresses(createGetRequestMapperParams.correctionsForm);
    this.setCommonRequest(request, createGetRequestMapperParams.correctionsForm, createGetRequestMapperParams.requestActionTypeCd);
    /* LECS-1062: this variable is needed to send the request directly to pending, rule only needed for requester and if is and is cancel charges */
    if (this.requestFormRolesService.sendManuallyRequestInd() && (createGetRequestMapperParams.correctionsForm?.tariff.code === RatingCancelChargesEnum.cts992 || createGetRequestMapperParams.correctionsForm?.cancelCharges.cancelChargesOpt)) {
      request.manuallyAppliedInd = true;
    }
    return request;
  }



  getBillCharges(): Observable<number | undefined> {
    let billCharges: CorrectionsBillChargesModel;

    return this.correctionsDetailDataService.getBillChargesFromStore()
      .pipe(
        map(billChrgs => {
          let totalPaymentAmt = 0;
          if (billChrgs instanceof Array && billChrgs[0] instanceof ErrorModel) {
            return totalPaymentAmt;
          }

          billCharges = billChrgs as CorrectionsBillChargesModel;
          billCharges.paymentDetails.arPaymentDetails.forEach((arPaymentDetail) => {
            totalPaymentAmt += arPaymentDetail.totalPaymentAmt;
          });
          return totalPaymentAmt;
        }));
  }

  getCorrectionRequest(createGetRequestMapperParams: CreateGetRequestMapperParams, correctionsDetail: CorrectionsDetailModel | undefined): CorrectionRequest {
    const { correctionsForm, requestActionTypeCd } = createGetRequestMapperParams;

    const response = new CorrectionRequest();
    response.auditorComment = correctionsForm.auditorRemarks.auditorComments;
    response.authorization = correctionsForm.auditorRemarks.authorityRemarks;
    response.priorityInd = correctionsForm.requestInfo.isDriverWaiting;
    response.rateInd = this.manualRateIndMapperService.getRateInd(correctionsForm.auditorRemarks.manualRateInd);
    response.reasonCd = correctionsForm.reason.description;
    response.requesterComment = correctionsForm.requestInfo.requesterComments;
    response.requestInvoiceInd = !correctionsForm.auditorRemarks.doNotSetInvoiceAuditor;
    response.reviewQueue = correctionsForm.requestInfo.correctionQueue;
    response.correctionRequestInstId = this.correctionsCreateRequestCommonMapperService.getCorrectionRequestInstId(requestActionTypeCd) as number;
    response.informationRequestInd = this.getInformationRequestInd(requestActionTypeCd) as boolean;

    if (correctionsDetail) {
      // NOTE: With backend team we agreed to get this data always from shipment api to send even for auditor function actions (hold, more info, approved, not approved)
      response.shipmentInstId = correctionsDetail.shipmentInstId;
      if (requestActionTypeCd === RequestActionTypeCd.submit) {
        response.requesterId = correctionsDetail.requestInformation.requesterUserId;
      }
    }

    return response;
  }

  // Default Request Form Methods *******************************************************************************
  private setCommonRequest(request: UpsertCorrectionRequestRqst, correctionsForm: CorrectionsFormValueModel, requestActionTypeCd: RequestActionTypeCd): void {
    if (correctionsForm.auditEscalationInd !== undefined) request.auditEscalationInd = correctionsForm.auditEscalationInd.auditEscalationInd;
    request.amcAmount = correctionsForm.amcAmount.amount;
    request.cancelChargesInd = correctionsForm.cancelCharges.cancelChargesOpt;
    request.rateTypeCd = correctionsForm.miscellaneous.type;
    request.ratingInstructions = correctionsForm.miscellaneous.instructions;
    request.correctionActionTypeCd = this.correctionsCreateRequestCommonMapperService.getActionTypeCd(requestActionTypeCd);
  }

  private getAccessorials(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel): CorrectionAccessorial[] {
    if (!correctionsForm.accessorials) return [];

    let accessorials: CorrectionAccessorial[] = [];

    correctionsForm.accessorials.forEach(acc => {
      if (acc.delete) return undefined as any;

      const rate = this.transformCommonDataFieldsService.getNumberFromTextField(acc.rate);
      const amount = this.transformCommonDataFieldsService.getNumberFromTextField(acc.amount);

      const originalAccessorial = originalForm.accessorials.find(accessorial => !!accessorial.sequenceNbr && accessorial.sequenceNbr === acc.sequenceNbr);
      const minChargeInd = this.getMinimumChargeInd({
        originalRate: originalAccessorial?.rate,
        originalMinimumChargeInd: originalAccessorial?.minimumChargeInd,
        correctedRate: acc.rate,
        correctedMinimumChargeInd: acc.minimumChargeInd,
      });

      const accessorialRequest = {
        ...new CorrectionAccessorial(),
        // when it is a new accessorial the sequenceNbr should be equals null
        sequenceNbr: acc.sequenceNbr || null,
        accessorialCd: acc.code,
        accessorialDescription: acc.description,
        chargeAmount: { ...new MonetaryAmount(), amt: amount },
        tariffsRate: rate,
        minChargeInd
      } as CorrectionAccessorial;

      accessorials.push(accessorialRequest);
    });

    return accessorials;
  }

  private getAsDeficitWeight(correctionsForm: CorrectionsFormValueModel, weightType: CorrectionsWeightFormTypeEnum, originalForm: CorrectionsFormValueModel): MiscLineItem {
    if (!correctionsForm.weight || correctionsForm.weight.deleteWeight || correctionsForm.weight.optionSelected !== weightType) return undefined as any;

    const selectedOption = correctionsForm.weight.optionSelected === CorrectionsWeightFormTypeEnum.asWeight
    ? CorrectionsWeightFormTypeEnum.asWeight
    : CorrectionsWeightFormTypeEnum.deficitWeight;

    const asDeficitWeight = selectedOption === CorrectionsWeightFormTypeEnum.asWeight
      ? correctionsForm.weight.asWeight
      : correctionsForm.weight.deficitWeight;

    const quantity = this.transformCommonDataFieldsService.getNumberFromTextField(asDeficitWeight.quantity) as number;
    const amount = this.transformCommonDataFieldsService.getNumberFromTextField(asDeficitWeight.amount) as number;
    const rate = this.transformCommonDataFieldsService.getNumberFromTextField(asDeficitWeight.rate) as number;

    if (!asDeficitWeight.description
      && quantity === 0
      && amount === 0
      && rate === 0
    ) {
      return undefined as any;
    }

    const originalWeight = selectedOption === CorrectionsWeightFormTypeEnum.asWeight
    ? originalForm.weight?.asWeight
    : originalForm.weight?.deficitWeight;

    const minimumChargeInd = this.getMinimumChargeInd({
      originalRate: originalWeight?.rate,
      originalMinimumChargeInd: originalWeight?.minimumChargeInd,
      correctedRate: asDeficitWeight.rate,
      correctedMinimumChargeInd: asDeficitWeight.minimumChargeInd,
    }) as boolean;

    const lineTypeCd = selectedOption === CorrectionsWeightFormTypeEnum.asWeight
      ? MiscLineItemCd.AS_WGT
      : MiscLineItemCd.DEFICIT_WGT;

    return {
      ...new MiscLineItem(),
      lineTypeCd,
      description: asDeficitWeight.description,
      quantity,
      amount,
      tariffsRate: rate,
      minimumChargeInd
    };
  }

  private getBeyondCarrier(correctionsForm: CorrectionsFormValueModel): AdvanceBeyondCarrier {
    if (correctionsForm.beyondScac.deleteScac) return undefined as any;

    const chargeAmount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.beyondScac?.beyondRevenue) as number;

    if (!correctionsForm.beyondScac.scacCode && chargeAmount === 0) return undefined as any;

    return {
      ...new AdvanceBeyondCarrier(),
      typeCd: AdvanceBeyondTypeCd.BYD_CARR,
      carrierScacCd: correctionsForm.beyondScac.scacCode as string,
      chargeAmount
    };
  }

  private getBillTo(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (correctionsForm.billto?.deleteBillTo) {
      return undefined as any;
    }

    if(
      correctionsForm.billto?.customerNumber == null &&
      !correctionsForm.billto?.madCode &&
      !correctionsForm.billto?.nameOne &&
      !correctionsForm.billto?.nameTwo &&
      !correctionsForm.billto?.address &&
      !correctionsForm.billto?.city &&
      !correctionsForm.billto?.state &&
      !correctionsForm.billto?.zipCode &&
      !correctionsForm.billto?.zipCodeComplementation &&
      !correctionsForm.billto?.country
    ) return undefined as any;

    const billTo = new AsMatchedParty();

    billTo.cisCustNbr = correctionsForm.billto.customerNumber != null ? correctionsForm.billto.customerNumber : (null as any);
    billTo.asMatchedMadCd = correctionsForm.billto.madCode;
    billTo.name1 = correctionsForm.billto.nameOne;
    billTo.name2 = correctionsForm.billto.nameTwo;
    billTo.address = correctionsForm.billto.address;
    billTo.city = correctionsForm.billto.city;
    billTo.stateCd = correctionsForm.billto.state;
    billTo.countryCd = correctionsForm.billto.country;
    billTo.zip6 = correctionsForm.billto.zipCode;
    billTo.zip4RestUs = correctionsForm.billto.zipCodeComplementation;
    billTo.typeCd = correctionsForm.chargeCode?.chargeCode === ChargeToCd.COLL ? MatchedPartyTypeCd.BILL_TO_INB : MatchedPartyTypeCd.BILL_TO_OTB;
    return billTo;
  }

  private getConsignee(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.consignee) return undefined as any;

    if(
      correctionsForm.consignee?.customerNumber == null &&
      !correctionsForm.consignee?.madCode &&
      !correctionsForm.consignee?.nameOne &&
      !correctionsForm.consignee?.nameTwo &&
      !correctionsForm.consignee?.address &&
      !correctionsForm.consignee?.city &&
      !correctionsForm.consignee?.state &&
      !correctionsForm.consignee?.zipCode &&
      !correctionsForm.consignee?.zipCodeComplementation &&
      !correctionsForm.consignee?.country
    ) return undefined as any;

    const consignee = new AsMatchedParty();

    consignee.cisCustNbr = correctionsForm.consignee.customerNumber != null ? correctionsForm.consignee.customerNumber : (null as any);
    consignee.asMatchedMadCd = correctionsForm.consignee.madCode;
    consignee.name1 = correctionsForm.consignee.nameOne;
    consignee.name2 = correctionsForm.consignee.nameTwo;
    consignee.address = correctionsForm.consignee.address;
    consignee.city = correctionsForm.consignee.city;
    consignee.stateCd = correctionsForm.consignee.state;
    consignee.countryCd = correctionsForm.consignee.country;
    consignee.zip6 = correctionsForm.consignee.zipCode;
    consignee.zip4RestUs = correctionsForm.consignee.zipCodeComplementation;
    consignee.typeCd = MatchedPartyTypeCd.CONS;
    consignee.creditStatusCd = correctionsForm.consignee.creditStatus;
    return consignee;
  }

  private getCorrectionDetail(): CorrectionsDetailModel | undefined {
    let correctionsDetail: CorrectionsDetailModel | undefined;

    this.correctionsHandler.getCorrectionsDetail$.pipe(
      take(1)
    ).subscribe(
      corrDetail => correctionsDetail = corrDetail
    );

    return correctionsDetail;
  }

  private getShipper(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.shipper) return undefined as any;

    if(
      correctionsForm.shipper?.customerNumber == null &&
      !correctionsForm.shipper?.madCode &&
      !correctionsForm.shipper?.nameOne &&
      !correctionsForm.shipper?.nameTwo &&
      !correctionsForm.shipper?.address &&
      !correctionsForm.shipper?.city &&
      !correctionsForm.shipper?.state &&
      !correctionsForm.shipper?.zipCode &&
      !correctionsForm.shipper?.zipCodeComplementation &&
      !correctionsForm.shipper?.country
    ) return undefined as any;

    const shipper = new AsMatchedParty();

    shipper.cisCustNbr = correctionsForm.shipper.customerNumber != null ? correctionsForm.shipper.customerNumber : (null as any);
    shipper.asMatchedMadCd = correctionsForm.shipper.madCode;
    shipper.name1 = correctionsForm.shipper.nameOne;
    shipper.name2 = correctionsForm.shipper.nameTwo;
    shipper.address = correctionsForm.shipper.address;
    shipper.city = correctionsForm.shipper.city;
    shipper.stateCd = correctionsForm.shipper.state;
    shipper.countryCd = correctionsForm.shipper.country;
    shipper.zip6 = correctionsForm.shipper.zipCode;
    shipper.zip4RestUs = correctionsForm.shipper.zipCodeComplementation;
    shipper.typeCd = MatchedPartyTypeCd.SHPR;
    shipper.creditStatusCd = correctionsForm.shipper.creditStatus;
    return shipper;
  }

  private getCashPrepaidLine(correctionsForm: CorrectionsFormValueModel): MiscLineItem {
    if (correctionsForm.cashPaid.delete) return undefined as any;

    const amount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.cashPaid.amount) as number;
    if (amount === 0 && !correctionsForm.cashPaid.description) return undefined as any;

    return {
      ...new MiscLineItem(),
      lineTypeCd: MiscLineItemCd.CASH_PPD_LN,
      amount,
      description: correctionsForm.cashPaid.description
    };
  }

  private getCodAmountLine(correctionsForm: CorrectionsFormValueModel): MiscLineItem {
    if (correctionsForm.codAmount.delete) return undefined as any;

    const amount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.codAmount.amount) as number;
    if (!correctionsForm.codAmount.description && amount === 0) return undefined as any;

    return {
      ...new MiscLineItem(),
      lineTypeCd: MiscLineItemCd.COD_AMT,
      amount,
      description: correctionsForm.codAmount.description
    };
  }

  private getCommodities(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel): Commodity[] {
    if (!correctionsForm.commodities) return [];

    let commodities: Commodity[] = [];

    correctionsForm.commodities.forEach(commodity => {
      if (commodity.delete) return undefined as any;

      const rate = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.rate);
      const amount = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.amount);
      const weight = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.weight);
      const pieces = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.pieces);

      const originalCommodity = originalForm.commodities.find(comm => !!comm.sequenceNbr && comm.sequenceNbr === commodity.sequenceNbr);
      const minimumChargeInd = this.getMinimumChargeInd({
        originalRate: originalCommodity?.rate,
        originalMinimumChargeInd: originalCommodity?.minimumChargeInd,
        correctedRate: commodity?.rate,
        correctedMinimumChargeInd: commodity?.minimumChargeInd,
      });

      const commodityReq = {
        ...new Commodity(),
        // when it is a new comodity the sequenceNbr should be equals null
        sequenceNbr: commodity.sequenceNbr || null,
        amount: amount,
        classType: commodity.class,
        description: commodity.description,
        nmfcItemCd: commodity.nmfc,
        packageCd: commodity.pkg,
        piecesCount: pieces,
        weightLbs: weight,
        tariffsRate: rate,
        hazardousMtInd: commodity.hazmat,
        minimumChargeInd
      } as Commodity;

      commodities.push(commodityReq);
    });

    return commodities;
  }

  private getCustomInBond(correctionsForm: CorrectionsFormValueModel): Bond {
    if (!correctionsForm.customInBond?.number && !correctionsForm.customInBond?.city && !correctionsForm.customInBond?.state) {
      return undefined as any;
    }

    return {
      bondNbr: correctionsForm.customInBond.number,
      city: correctionsForm.customInBond.city,
      state: correctionsForm.customInBond.state
    };
  }

  private getDiscountAmountLine(correctionsForm: CorrectionsFormValueModel): MiscLineItem {
    if (correctionsForm.discountAmount.delete) return undefined as any;

    const amount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.discountAmount.amount) as number;
    if (amount === 0 && !correctionsForm.discountAmount.description) return undefined as any;

    return {
      ...new MiscLineItem(),
      lineTypeCd: MiscLineItemCd.DISC_LN,
      description: correctionsForm.discountAmount?.description,
      amount
    };
  }

  private getInformationRequestInd(requestActionTypeCd: RequestActionTypeCd): boolean | undefined {
    if (requestActionTypeCd !== RequestActionTypeCd.reSubmit) return;
    
    const correctedDetail = this.correctionsCreateRequestCommonMapperService.getCorrectedDetail();
    return correctedDetail?.informationRequestInd?.informationRequestInd ? false : undefined;
  }

  private getMeasures(correctionsForm: CorrectionsFormValueModel): { linealFootTotalNbr: number, motorizedPiecesCount: number, totalPalletCount: number } {
    // NOTE: these values must be equals null when the delete check is marked
    const linealFootTotalNbr = correctionsForm.measures.deleteLinealFeet ? null as any : this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.measures.linealFeet) as number;
    const motorizedPiecesCount = correctionsForm.measures.deleteMotorized ? null as any : this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.measures.motorizedPcs) as number;
    const totalPalletCount = correctionsForm.measures.deletePalletCount ? null as any : this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.measures.palletCount) as number;

    return { linealFootTotalNbr, motorizedPiecesCount, totalPalletCount };
  }

  private getPickupDate(correctionsDetail: CorrectionsDetailModel | undefined, requestActionTypeCd: RequestActionTypeCd): string | undefined {
    if (this.correctionsCreateRequestCommonMapperService.isMainAction(requestActionTypeCd)) {
      return correctionsDetail?.pickupDate;
    }

    const correctedDetail = this.correctionsCreateRequestCommonMapperService.getCorrectedDetail();
    return correctedDetail?.pickupDate;
  }

  private getShipment(createGetRequestMapperParams: CreateGetRequestMapperParams, correctionsDetail: CorrectionsDetailModel | undefined): CorrectionShipment {
    const { correctionsForm, totalPaymentAmount, requestActionTypeCd, originalForm } = createGetRequestMapperParams;

    const response = new CorrectionShipment();
    const { ratingTariffsId, tariffEffectiveDate } = this.getTariffValues(correctionsForm, requestActionTypeCd);

    response.accessorials = this.getAccessorials(correctionsForm, originalForm);
    response.advanceCarrier = this.correctionsCreateRequestCommonMapperService.getAdvanceCarrier(correctionsForm);
    response.asWeightLine = this.getAsDeficitWeight(correctionsForm, CorrectionsWeightFormTypeEnum.asWeight, originalForm);
    response.beyondCarrier = this.getBeyondCarrier(correctionsForm);
    response.billTo = this.getBillTo(correctionsForm);
    response.cashCollectedInd = correctionsForm.cashCollected.collectedInd;
    response.cashPrepaidLine = this.getCashPrepaidLine(correctionsForm);
    response.chargeToCd = correctionsForm.chargeCode.chargeCode;
    response.codAmountLine = this.getCodAmountLine(correctionsForm);
    response.collectLine = this.correctionsCreateRequestCommonMapperService.getCollectLine(correctionsForm) as MiscLineItem;
    response.commodities = this.getCommodities(correctionsForm, originalForm);
    response.consignee = this.getConsignee(correctionsForm);
    response.customsInBond = this.getCustomInBond(correctionsForm);
    response.deficitWeightLine = this.getAsDeficitWeight(correctionsForm, CorrectionsWeightFormTypeEnum.deficitWeight, originalForm);
    response.destinationTerminalSic = correctionsForm.sic.destination;
    response.discountAmountLine = this.getDiscountAmountLine(correctionsForm);
    response.discountPercentage = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.discountAmount.percentage) as number;
    response.guaranteedInd = correctionsForm.guaranteed.indicator;
    response.originTerminalSic = correctionsForm.sic.origin;
    response.pickupDate = this.getPickupDate(correctionsDetail, requestActionTypeCd) as string;
    response.prepaidLine = this.correctionsCreateRequestCommonMapperService.getPrepaidLine(correctionsForm);
    response.ratingTariffsId = ratingTariffsId as string;
    response.shipmentRemarks = this.getShippingRemarks(correctionsForm);
    response.shipper = this.getShipper(correctionsForm);
    response.tariffEffectiveDate = tariffEffectiveDate as string;
    response.totalPaymentAmount = totalPaymentAmount as number;

    const { linealFootTotalNbr, motorizedPiecesCount, totalPalletCount } = this.getMeasures(correctionsForm);
    response.linealFootTotalNbr = linealFootTotalNbr;
    response.motorizedPiecesCount = motorizedPiecesCount;
    response.totalPalletCount = totalPalletCount;

    return response;
  }

  private getShippingRemarks(correctionsForm: CorrectionsFormValueModel): string {
    return correctionsForm.shippingRemarks.deleteInd
      ? undefined as any : correctionsForm.shippingRemarks.remarks;
  }

  private getTariffCode(correctionsForm: CorrectionsFormValueModel, requestActionTypeCd: RequestActionTypeCd): string | undefined {
    const tariffCode = correctionsForm.tariff?.code;
    if (!tariffCode) return;
    
    const manualRateInd = correctionsForm.auditorRemarks.manualRateInd;
    const isMainAction = this.correctionsCreateRequestCommonMapperService.isMainAction(requestActionTypeCd);
    const ratingTariffsId = isMainAction ? this.correctionsCreateRequestCommonMapperService.getTariffCodeValue(tariffCode, !manualRateInd) : tariffCode;

    return ratingTariffsId;
  }

  private getTariffValues(correctionsForm: CorrectionsFormValueModel, requestActionTypeCd: RequestActionTypeCd): { tariffEffectiveDate: string | undefined, ratingTariffsId: string | undefined } {
    const tariffEffectiveDate = correctionsForm?.tariff?.effectiveDate ? DateUtils.momentToString(correctionsForm.tariff.effectiveDate, DateFormat.yearMonthDayFormat) : undefined;
    const ratingTariffsId = this.getTariffCode(correctionsForm, requestActionTypeCd);

    return { tariffEffectiveDate, ratingTariffsId };
  }

  private getMinimumChargeInd(props: {
    originalRate?: number | string | null,
    originalMinimumChargeInd?: boolean | null,
    correctedRate?: number | string | null,
    correctedMinimumChargeInd?: boolean | null
  }): boolean | undefined | null {
    return props.originalRate == props.correctedRate ? props.originalMinimumChargeInd : props.correctedMinimumChargeInd;
  }
}
