import { Injectable } from '@angular/core';
import {
  CorrectionsBillRequestSectionTypeEnum,
  CorrectionsRequestStatusEnum,
} from '@shared/enums/corrections/corrections-request-status.enum';
import { CommonDateMapperService } from '@shared/mappers/common/common-date/common-date-mapper.service';
import {
  CorrectionRequestsAndHistoryQueryModel,
  CorrectionsRequestAndHistoryAuditModel,
  CorrectionsRequestAndHistoryModel,
} from '@shared/models/corrections/corrections-request-and-history.model';
import { ApiResponseModel } from '@shared/models/shared/api-response.model';
import {
  CorrectionsRequestAndHistoryListType,
  CorrectionsRequestAndHistoryType,
} from '@shared/types/corrections/corrections-request-and-history.types';
import { RegexRules } from '@shared/utils/regex/regex.utils';
import {
  CorrectionRequestAndHistory,
  CorrectionRequestHist,
  DeleteCorrectionRequestsQuery,
  ListCorrectionRequestsAndHistoryQuery,
  ListCorrectionRequestsAndHistoryResp,
} from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable()
export class CorrectionsRequestAndHistoryMapperService {
  serviceName = 'CorrectionsRequestAndHistoryService';

  constructor(private commonDateMapperService: CommonDateMapperService) {}

  private getDateTime(dateTime: Date): number | undefined {
    if (!dateTime) return;

    try {
      return dateTime ? this.commonDateMapperService.getTimeStampNumberFromApi(dateTime) : undefined;
    } catch (error) {
      console.log(error, this.serviceName);
      return;
    }
  }

  getAuditInfo(correctionRequestAndHistories: CorrectionRequestAndHistory): CorrectionsRequestAndHistoryAuditModel[] {
    if (
      !correctionRequestAndHistories?.correctionRequestHists ||
      correctionRequestAndHistories.correctionRequestHists.length === 0
    )
      return [];
    return correctionRequestAndHistories.correctionRequestHists.map((requestHists: CorrectionRequestHist) => {
      const completeDateTime = this.getDateTime(requestHists.createdTimestamp);
      const response: CorrectionsRequestAndHistoryAuditModel = {
        auditorName: requestHists.auditorName,
        completeDateTime,
        queueName: requestHists.reviewQueue,
        informationRequestInd: requestHists.informationRequestInd,
        status: requestHists.statusCd,
        reasonDescription: requestHists.reason?.reasonDescription,
      };
      return response;
    });
  }

  getRequestAndHistory(
    correctionRequestAndHistories: CorrectionRequestAndHistory
  ): CorrectionsRequestAndHistoryModel | undefined {
    if (!correctionRequestAndHistories.correctionRequest) return;

    try {
      const completeDateTime = this.getDateTime(correctionRequestAndHistories.correctionRequest.lastSubmitDateTime);
      const dateTime = this.getDateTime(correctionRequestAndHistories.correctionRequest.auditInfo?.createdTimestamp);
      const requestDateTime = this.getDateTime(
        correctionRequestAndHistories.correctionRequest.auditInfo?.createdTimestamp
      );
      const lastUpdatedDateTime = this.getDateTime(
        correctionRequestAndHistories.correctionRequest.auditInfo?.updatedTimestamp
      );

      return {
        correctionRequestInstId: correctionRequestAndHistories.correctionRequest.correctionRequestInstId,
        proNbr: correctionRequestAndHistories.correctionRequest.proNbr,
        pickupDate: correctionRequestAndHistories.correctionRequest.pickupDate,
        requestId: correctionRequestAndHistories.correctionRequest.correctionRequestInstId,
        requesterId: correctionRequestAndHistories.correctionRequest.requesterId,
        auditorName: correctionRequestAndHistories.correctionRequest.auditorName,
        completeDateTime,
        dateTime,
        queueName: correctionRequestAndHistories.correctionRequest.reviewQueue,
        reasonDescription: correctionRequestAndHistories.correctionRequest.reason?.reasonDescription,
        requestDateTime,
        lastUpdatedDateTime,
        requesterName: correctionRequestAndHistories.correctionRequest.requesterName,
        status: correctionRequestAndHistories.correctionRequest.statusCd,
        updateDelivery: correctionRequestAndHistories.correctionRequest.priorityInd,
        auditInfo: this.getAuditInfo(correctionRequestAndHistories),
      };
    } catch (error) {
      console.error('Error mapping request and history data, CorrectionsRequestAndHistoryMapperService');
      throw new Error(error as string);
    }
  }

  getRequestAndHistoryFromApi(
    listCorrections: ListCorrectionRequestsAndHistoryResp
  ): ApiResponseModel<CorrectionsRequestAndHistoryListType> {
    return {
      data: listCorrections?.correctionRequestAndHistories?.map((requestAndHistory) =>
        this.getRequestAndHistory(requestAndHistory)
      ) || [],
      listInfo: listCorrections?.listInfo || undefined,
    };
  }

  getRequestAuditInfoFromApi(
    listCorrections: ListCorrectionRequestsAndHistoryResp
  ): ApiResponseModel<CorrectionsRequestAndHistoryType> {
    return {
      data: this.getRequestAndHistory(listCorrections.correctionRequestAndHistories[0]),
      listInfo: listCorrections.listInfo,
    };
  }

  getRequestForRequestAuditInfo(requestId: number, includeHistoryInd: boolean): ListCorrectionRequestsAndHistoryQuery {
    const queryParams = new ListCorrectionRequestsAndHistoryQuery();
    queryParams.correctionRequestInstId = requestId;
    queryParams.includeHistoryInd = includeHistoryInd;

    return queryParams;
  }

  private setRequestAndHistoryStatus(sectionType: CorrectionsBillRequestSectionTypeEnum): string | undefined {
    if (sectionType === CorrectionsBillRequestSectionTypeEnum.history) {
      return `
      ${CorrectionsRequestStatusEnum.keyWordToFilter},
      ${CorrectionsRequestStatusEnum.applied},
      ${CorrectionsRequestStatusEnum.declined}
    `.replace(RegexRules.findBlankSpaces, '');
    }

    if (sectionType === CorrectionsBillRequestSectionTypeEnum.pending) {
      return `
        ${CorrectionsRequestStatusEnum.keyWordToFilter},
        ${CorrectionsRequestStatusEnum.locked},
        ${CorrectionsRequestStatusEnum.pending},
        ${CorrectionsRequestStatusEnum.saved}
      `.replace(RegexRules.findBlankSpaces, '');
    }
    return;
  }

  getRequestForRequestAndHistory(
    correctionRequestsAndHistoryQuery: CorrectionRequestsAndHistoryQueryModel
  ): ListCorrectionRequestsAndHistoryQuery {
    const queryParams = new ListCorrectionRequestsAndHistoryQuery();
    queryParams.correctionQueue = correctionRequestsAndHistoryQuery.correctionQueue;
    queryParams.includeHistoryInd = correctionRequestsAndHistoryQuery.includeHistoryInd;
    queryParams.listInfo = correctionRequestsAndHistoryQuery.listInfo;
    queryParams.proNbr = correctionRequestsAndHistoryQuery.proNbr;
    queryParams.auditorFunctionInd = correctionRequestsAndHistoryQuery.auditorFunctionInd;
    queryParams.statusCd =
      this.setRequestAndHistoryStatus(correctionRequestsAndHistoryQuery.sectionType) ||
      correctionRequestsAndHistoryQuery.statusCd;

    return queryParams;
  }

  getRequestForDeleteCorrectionRequests(requestIds: number[]): DeleteCorrectionRequestsQuery {
    const queryParams = new DeleteCorrectionRequestsQuery();
    queryParams.correctionRequestInstIds = requestIds;

    return queryParams;
  }
}
